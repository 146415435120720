import React, {useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useCookies } from 'react-cookie';
import $ from 'jquery';
import CountriesRegister from '../global-component/CountriesRegister';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import Loader1 from '../global-component/Loader1';
import swal from "sweetalert2";
import { GoogleLogin } from '@react-oauth/google';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

function RegisterNow () {

<ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
//transition: Bounce,
/>
    const [showcurrency, setShowCurrency] = useState();
    const defaultFormFields = {
        registertype:'',
fname:'',
lname:'',
country:'',
countrycode:'',
phoneno:'',
email:'',
confirmemail:'',
companyname:'',
Currency:'',
password:'',
Confirm_password:'',
terms:false,
totalexperience:'',
timezone:'',
offset:'',
pkgid:'',

      };

    const [formFields, setFormFields] = useState(defaultFormFields);
    const {registertype,        
    fname,
    lname,
    country,
    countrycode,
    phoneno,
    email,
    confirmemail,
    companyname,
    Currency,
    password,
    Confirm_password,
    terms,
    totalexperience,
    timezone,
    offset,
    pkgid } = formFields;
    const [isChecked, setIsChecked] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const handleChange = (event) => {
        const { name, value } = event.target;    
        setFormFields({ ...formFields, [name]: value });
        if(name=="password")
        {
            if(!isPasswordStrong(value))
            {
                setErrorMessage('Password should contain at least one number, one uppercase, one lowercase, one special character and minimam 6 letter.');                
            }
            else{
                setErrorMessage('');
            } 
        }

        if(name =="confirmemail"){
            if(formFields.email ==value)
                {
                    $("#cnfrmemailmatch").html("");
                    //$("#cnfrmemailmatch").css("color","green");
                    //$("#cnfrmemailmatch").css("float","left");
                }
                else{
                    $("#cnfrmemailmatch").html("Email not Matched");
                    $("#cnfrmemailmatch").css("color","red");
                    $("#cnfrmemailmatch").css("float","left");
                }
        }

        if(name =="Confirm_password"){
            if(formFields.password ==value)
                {
                    $("#cnfrmpsmatch").html("");
                   // $("#cnfrmpsmatch").css("color","green");
                   // $("#cnfrmpsmatch").css("float","left");
                }
                else{
                    $("#cnfrmpsmatch").html("Password not Matched");
                    $("#cnfrmpsmatch").css("color","red");
                    $("#cnfrmpsmatch").css("float","left");
                }
        }


      };

      const isPasswordStrong = (password) => {            
        // const number     = /([0-9])/;
        // const upperCase  = /([A-Z])/;
        // const lowerCase  = /([a-z])/;
        // const specialCharacters = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;
        // return number.test(password) && upperCase.test(password) && lowerCase.test(password) && specialCharacters.test(password);

        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/;
        return passwordRegex.test(password);
      };


    const handleChangeterm = (event) => {
        setIsChecked(event.target.checked);
        setFormFields({...formFields, [event.target.name]: event.target.checked});
       


    };
   useEffect(() => {
    $("#thanksubmit").hide();
        $("#formsubmit").show();  
    const queryParams = new URLSearchParams(window.location.search);
    const pckjid = queryParams.get('pkjid');
   // console.log(pckjid);
    fetchcurrentcountry();
  }, []);
  const [cookies, setCookie] = useCookies(['access_token', 'refresh_token']);
  const [apiKey, setApiKey] = useState('87d7263abc094f35a77b313760406905');
  const [flag, setFlag] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordVisibilityConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };


  async function fetchcurrentcountry(){
    fetch('https://api.ipify.org/?format=json')
    .then(response => response.json())
    .then(data =>getcountrydata(data.ip))
    .catch(error => console.error('Error fetching IP address:', error));
  };
  
async function getcountrydata(ip){
    fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${apiKey}&ip=${ip}`)
    .then(response => response.json())
    .then(data => showcountrydata(data))
    .catch(error => console.error('Error fetching IP details:', error));
}

async function showcountrydata(data){
   // debugger;
setFormFields({...formFields, 
    Currency:data.currency.code,
    country:data.country_name,
    timezone:data.time_zone.name,
    offset:data.time_zone.offset
    });
    setShowCurrency(data.currency.code);
    let cntrycode2=data.country_code2.toLowerCase();
    setFlag(cntrycode2);
    $("#phonecode").html(data.calling_code);
    //console.log(data);

    localStorage.setItem('currentcountry', data.country_name);
    localStorage.setItem('currentcallingcode', data.calling_code);            
    localStorage.setItem('currentflag', cntrycode2);
    localStorage.setItem('currentcurrency', data.currency.code); 


}
  const handleSelect = (vcurrency,country,flag,code) => {  
    setFormFields({...formFields, 
    Currency:vcurrency,
    country:country
    });
    let flagvalue = flag.toLowerCase();
    setFlag(flagvalue);
    setShowCurrency(vcurrency);
    $("#phonecode").html(code);
  };


  const handlesubmit = async (event) => {
    event.preventDefault();
   
 if(formFields.registertype===""){
    swal.fire({
        title: '<span style="color:#f27474">Opps!</span>',
        html: "Please Select Register Type! <br /><br /><a href='javascript:swal.close();' class='btn btn-primary btn-sm'>OK</a>",
        type: 'error',
        icon: "error",
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false
    });
      
    return; 
 }
 if(formFields.terms==false){
    swal.fire({
        title: '<span style="color:#f27474">Opps!</span>',
        html: "Please Select Terms and Privacy Policy <br /><br /><a href='javascript:swal.close();' class='btn btn-primary btn-sm'>OK</a>",
        type: 'error',
        icon: "error",
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false
    });
      
    return;    
 }

 if(formFields.email==formFields.confirmemail){
       
 }
 else{
    swal.fire({
        title: '<span style="color:#f27474">Opps!</span>',
        html: "Confirm Email not Matched <br /><br /><a href='javascript:swal.close();' class='btn btn-primary btn-sm'>OK</a>",
        type: 'error',
        icon: "error",
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false
    });
      
    return; 
 }

 if(formFields.password==formFields.Confirm_password){
       
 }
 else{
    swal.fire({
        title: '<span style="color:#f27474">Opps!</span>',
        html: "Confirm Password not Matched <br /><br /><a href='javascript:swal.close();' class='btn btn-primary btn-sm'>OK</a>",
        type: 'error',
        icon: "error",
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false
    });
      
    return; 
 }



 const axios = require('axios');

 let datas = JSON.stringify({
   "registerdata": {           
    "registertype":formFields.registertype,
    "fname":formFields.fname,
    "lname":formFields.lname,
    "country":formFields.country,
    "countrycode":$("#phonecode").html(),
    "phoneno":formFields.phoneno,
    "email":formFields.email,
    "confirmemail":formFields.confirmemail,
    "companyname":formFields.companyname,
    "Currency":formFields.Currency,
    "password":formFields.password,
    "Confirm_password":formFields.Confirm_password,
    "terms":formFields.terms,
    "totalexperience":formFields.totalexperience,
    "timezone":formFields.timezone,
    "offset":formFields.offset,
    "pkgid":formFields.pkgid,
   }
 });
 //console.log(datas);
 let config = {
   method: 'post',
   maxBodyLength: Infinity,
   headers: { 
     'Content-Type': 'application/json'
   },
   body : datas
 };

 fetch('https://zwt.zwato.com/CreateAccounts.aspx/APISaveRegister', config)
 .then(response => {
     if (!response.ok) {
       throw new Error('Network response was not ok');
     }
     return response.json(); // Extract the response body as JSON
   })
   .then(data => {   
    

     if (data.d === 'checkterm') {
       // Handle the case when user is not found
       swal.fire({
        title: '<span style="color:#f27474">Opps!</span>',
        html: "Please Select Terms and Privacy Policy <br /><br /><a href='javascript:swal.close();' class='btn btn-primary btn-sm'>OK</a>",
        type: 'error',
        icon: "error",
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false
    });
   
      return;
     }
     else if(data.d === 'allreadyexist'){
        swal.fire({
            title: '<span style="color:#f27474">Opps!</span>',
            html: "Email Address is Already Exists! <br /><br /><a href='javascript:swal.close();' class='btn btn-primary btn-sm'>OK</a>",
            type: 'error',
	        icon: "error",
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false
        });
    
        return;
     }
     else if(data.d === 'submited'){
       $("#thanksubmit").show();
        $("#formsubmit").hide();  
     
     }
   })      
 .catch((error) => {
   console.log(error);
 });

 
  }


  //////////////////////////////////////////////// handle handlesignwithmicrosoft ///////////////
const handlesignwithmicrosoft =() =>{
    window.location.href="https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=260368b9-0101-4720-b0d8-ff3337affe72&response_type=code&redirect_uri=https://app.zwato.com/LoginMicrosoft&response_mode=query&scope=User.Read";
  }



///////////////////////////////////////////////////// Login with Google /////////////////////////


const [ user, setUser ] = useState([]);
    const [ profile, setProfile ] = useState([]);

// const responseMessage = (response) => {
//   console.log(response);
// };
// const errorMessage = (error) => {
//   console.log(error);
// };
       const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });  


    useEffect(
     
      () => {
        fetchcurrentcountry();
          if (user) {
              axios
                  .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                      headers: {
                          Authorization: `Bearer ${user.access_token}`,
                          Accept: 'application/json'
                      }
                  })
                  .then((res) => {
                      setProfile(res.data);
// console.log(res.data.email);
// console.log(res.data.name);
// console.log(localStorage.getItem("currentcountry"));
// console.log(localStorage.getItem("currentcallingcode"));
// console.log(localStorage.getItem("currentcurrency"));
// console.log(res.data);

                      const axios = require('axios');
                      let datas = JSON.stringify({
                        "googlelogindata": {           
                          "Email": res.data.email,
                          "FName": res.data.given_name,
                          "LName": res.data.family_name,
                          "country": localStorage.getItem("currentcountry"),
                          "currency": localStorage.getItem("currentcurrency"),
                          "countrycode": localStorage.getItem("currentcallingcode"),
                          "registertype":"Company",
                          "keepmesign": true 
                        }
                      });
                     // console.log(datas);
                      let config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        headers: { 
                          'Content-Type': 'application/json'
                        },
                        body : datas
                      };
                      
                      fetch('https://zwt.zwato.com/Logins.aspx/ReactGoogleLogin', config)
                      .then(response => {
                          if (!response.ok) {
                            throw new Error('Network response was not ok');
                          }
                          return response.json(); // Extract the response body as JSON
                        })
                        .then(data => { 
                          fetchlogindetails(data.d);

                         
                        })      
                      .catch((error) => {
                       // console.log("Test Error-"+error);
                        alert(error +" - Please check internet connection or API");
                       // console.log(error);
                        
                      });




                  })
                  .catch((err) => console.log(err));
          }
      },
      [user]
  );

  async function fetchlogindetails(regid){      

    try {
        const response = await fetch('https://zwt.zwato.com/Invoice.aspx/GetCompanyDetails', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf-8'       
          },
          body: JSON.stringify({
            registrationid: regid       
          })
        });
        const data = await response.json();           
       //console.log(data.d.address1);  

     let expires = new Date()
     expires.setTime(expires.getTime() + (86000 * 1000));         
     setCookie('access_token', data.d.registrationid+''+data.d.ipaddress, { path: '/',  expires});
     setCookie('registrationid', data.d.registrationid, { path: '/',  expires});

     localStorage.setItem('email', data.d.email);
     localStorage.setItem('currency', data.d.Currency);
     localStorage.setItem('sitelanguage', data.d.language);
     localStorage.setItem('dateformate', data.d.dateformate);      
     localStorage.setItem('regstatus', data.d.status);   
     fetchcurrentcountry();

    window.location.href = '/dashboard';
    return false;        
      } catch (error) {
        // Handle errors here
        console.log("Test Error");
      }
  };

    let publicUrl = process.env.PUBLIC_URL + '/'
    return (
        <>
         <ToastContainer />
            <div className="login-8">
                <div className="container">
                <Form onSubmit={handlesubmit} id="formsubmit">
                    <div className="row justify-content-center login-box">
                       
                        <div className="col-lg-9 col-md-12 form-info">
                            <div className="form-section">
                                <div className="logo1 clearfix text-center">
                                    <Link to="/">
                                        <img src={publicUrl+"assets/images/logo-zwato.png"} alt="logo"/>
                                    </Link>
                                </div>                                
                                <h3 className="text-center">Register Now To Start Invoicing</h3>
                                <div className="login-inner-form">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <p><strong>Register as :</strong></p>
                                        </div>
                                        <div className="col-md-10">
                                            <div className="m-b-20 m-checkbox-inline justify-content-between custom-radio-ml">
                                                <div className="form-check form-check-inline radio radio-primary">
                                                    <input className="form-check-input" id="radioinline1" type="radio"  checked={formFields.registertype === 'Company'} onChange={handleChange} name="registertype" value="Company"/>
                                                    <label className="form-check-label mb-0" htmlFor="radioinline1">Company</label>
                                                </div>
                                                <div className="form-check form-check-inline radio radio-primary">
                                                    <input className="form-check-input" id="radioinline2" type="radio"  checked={formFields.registertype === 'Accountant'} onChange={handleChange} name="registertype" value="Accountant"/>
                                                    <label className="form-check-label mb-0" htmlFor="radioinline2">Accountant</label>
                                                </div>
                                                <div className="form-check form-check-inline radio radio-primary">
                                                    <input className="form-check-input" id="radioinline3" type="radio"  checked={formFields.registertype === 'Personal'} onChange={handleChange} name="registertype" value="Personal"/>
                                                    <label className="form-check-label mb-0" htmlFor="radioinline3">Personal</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div><br />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Group className="form-group form-box">
                                                <Form.Control type="text" name="fname" onChange={handleChange} value={fname} maxLength={"50"} required placeholder="First Name"/>
                                                <i className="fa-regular fa-user"></i>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="form-group form-box">
                                                <Form.Control type="text" name="lname" onChange={handleChange} value={lname} maxLength={"50"} placeholder="Last Name"/>
                                                <i className="fa-regular fa-user"></i>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <InputGroup>
                                                <InputGroup.Text id="basic-addon1"><i className={`fi fi-${flag}`}></i></InputGroup.Text>
                                                <CountriesRegister name="Country" onChange={handleSelect} selectedcountry={showcurrency} id="country" /> 
                                            </InputGroup>
                                        </div>
                                        <div className="col-md-6">
                                        <InputGroup className="form-group form-box">
                                        <InputGroup.Text id="phonecode"></InputGroup.Text>
                                                <Form.Control type="text" name="phoneno" className="number phoneformate" required onChange={handleChange} maxLength={"20"} value={phoneno} placeholder="Phone Number"/>
                                             <i className="fa-solid fa-mobile"></i>
                                                </InputGroup>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Group className="form-group form-box">
                                                <Form.Control type="email" name="email" required className="validemail" maxLength={"70"} onChange={handleChange} value={email} placeholder="Email ID"/>
                                                <i className="fa-regular fa-envelope"></i>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="form-group form-box">
                                                <Form.Control type="email" name="confirmemail" className="validemail" maxLength={"70"} onChange={handleChange} value={confirmemail} required placeholder="Confirm Email ID"/>
                                                <i className="fa-solid fa-envelope"></i>
                                                <span id="cnfrmemailmatch" style={{floar:"left"}}></span>
                                            </Form.Group>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Group className="form-group form-box">
                                                <Form.Control type="text" name="companyname" onChange={handleChange} value={companyname} maxLength={"100"} placeholder="Company Name"/>
                                                <i className="fa-sharp fa-solid fa-building"></i>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="form-group form-box">
                                                <Form.Control type="text" name="Currency" onChange={handleChange} value={Currency} placeholder="Currency"/>
                                                <i className="fa-regular fa-solid fa-money-bill"></i>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Group className="form-group form-box">
                                                <Form.Control type={showPassword ? "text" : "password"} name="password" required onChange={handleChange} value={password} placeholder="Password"/>
                                                <i onClick={togglePasswordVisibility}>
            {showPassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
          </i>
                                               
                                            </Form.Group>
                                            {errorMessage && <p style={{ color: 'red', fontSize:'9px', textAlign:'left', position:'relative', top:'-10px', clear:'both' }}>{errorMessage}</p>}
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="form-group form-box">
                                                <Form.Control type={showPasswordConfirm ? "text" : "password"} name="Confirm_password" onChange={handleChange} value={Confirm_password} placeholder="Confirm Password"/>
                                                <i onClick={togglePasswordVisibilityConfirm}>
            {showPassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
          </i>
          <span id="cnfrmpsmatch"></span>
                                               
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <div className="form-check checkbox checkbox-dark p-l-0">
                                                <input type="checkbox" name="terms" checked={isChecked} onChange={handleChangeterm} className="radio_animated check checkbox-custom" id="checkbox-custom_04"/>
                                                <label htmlFor="checkbox-custom_04" className="checkbox-custom-label mt-0">I have read and accept Zwato <Link to="#">Terms and Privacy Policy</Link></label>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="form-group mb-0">
                                        <Button className="btn btn-info-gradien w-100" type="submit"><i className="fa-solid fa-arrow-right-to-bracket"></i> Register Now</Button>                    
                                    </div>
                                    <div className="do-mobile mt-3">
                                        <div className="fxt-style-line">
                                            <h2 className="text-dark">Or Register With</h2>
                                        </div>
                                        <ul className="social-list">
                                            <li>
                                                <a href="#" className="google-bg">
                                                    <i className="fa-brands fa-google"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <Link to="#"  onClick={handlesignwithmicrosoft} className="microsoft-bg">
                                                    <i className="fa-brands fa-microsoft"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <p className="text">Already Have an Account? <Link to="/SignIn"> Sign In</Link></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12 bg-img">
                            <div className="info">
                                <div className="info-text">
                                    <div className="logo clearfix text-center">
                                        <Link to="/">
                                            <img src={publicUrl+"assets/images/logo-zwato-w.png"} alt="logo"/>
                                        </Link>
                                        <div className="fxt-style-line">
                                            <h2>Or Register With</h2>
                                        </div>
                                        <ul className="social-list">
                                            <li>
                                                <a href="#" className="google-bg" onClick={login}>
                                                    <i className="fa-brands fa-google"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <Link to="#" onClick={handlesignwithmicrosoft} className="microsoft-bg">
                                                    <i className="fa-brands fa-microsoft"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    </Form>

                   
     
                    <div className="login-8" id="thanksubmit">
                <div className="container">
                    <div className="row justify-content-center login-box">
                        <div className="col-lg-4 col-md-4 form-info">
                            <img src="https://zwt.zwato.com/images/inbox-icon-animation.gif" className="img-fluid"/>
                        </div>
                        <div className="col-lg-8 col-md-8 form-info">
                            <h3 className="">Thank You For Your Registration.</h3>
                            <p className="text-dark">Welcome to Zwato, the next-generation all-in-one software for entrepreneurs around the globe.</p>
                            <p className="text-dark">We have sent a verification email. <br/>Please check your inbox, including your junk or spam folder. <br/>Verify your email to continue. <a href="/signin" className="btn btn-square btn-light btn-xs f-12 text-danger">Back To Login Page</a></p>
                            <br/>
                            <p className="text-dark">
                                <a href="https://mail.google.com/" className="logincircle" target="_blank">
                                    <img src="https://zwt.zwato.com/images/gmail.png" className="img-fluid"/>
                                </a>
                                <a href="https://login.live.com/" className="logincircle" target="_blank">
                                    <img src="https://zwt.zwato.com/images/outlook.png" className="img-fluid"/>
                                </a>
                                <a href="https://login.yahoo.com/" className="logincircle" target="_blank">
                                    <img src="https://zwt.zwato.com/images/yahoo.png" className="img-fluid"/>
                                </a>
                                <a href="logins.aspx" className="logincircle">
                                    <img src="https://zwt.zwato.com/images/login.png" className="img-fluid"/>
                                </a>
                                <a href="https://mail.rediff.com/cgi-bin/login.cgi" className="logincircle" target="_blank">
                                    <img src="https://zwt.zwato.com/images/rediff.png" className="img-fluid"/>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>


          
     


                </div>
            </div>
        </>
    );

}
export  default RegisterNow;