import React from "react";

function Footer () {
    return (
        <>
            <footer className="footer">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-10 p-0 footer-left">
                    <p className="mb-0">Copyright 2023 © Softa Technology OY Pvt LTD</p>
                  </div>
                </div>
              </div>
            </footer>
        </>
    )
};

export default Footer;