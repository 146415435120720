import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loader1 = () => {
  return (
    <div style={{position:"fixed", height:"100%", width:"100%", lineHeight:"100%", zIndex:"99999"}}>
    <Spinner animation="border" role="status" style={{position:"fixed", top:"45%", left:"45%"}}>
      <span className="sr-only">Loading...</span>
    </Spinner>
    </div>  
  );
};

export default Loader1;