import React, { useState, useEffect } from "react";

function Loader () {
    const handleTapTopClick = () => {
        document.documentElement.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
    
        document.body.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
     // console.log("Loader 1");
        const fadeOutLoader = () => {
          const loaderWrapper = document.querySelector('.loader-wrapper');
    
          // Check if the loaderWrapper element exists before manipulating its style
          if (loaderWrapper) {
            loaderWrapper.style.transition = 'opacity 1s';
    
            setTimeout(() => {
              loaderWrapper.style.opacity = '0';
            }, 100);
    
            setTimeout(() => {
              loaderWrapper.remove();
            }, 1100);
          }
        };
    
        if (isLoading) {
          fadeOutLoader();
        }
      }, [isLoading]);
    return (
        <>
            <div className="tap-top" onClick={handleTapTopClick}><i data-feather="chevrons-up"></i></div>
            <div className="loader-wrapper">
            <div className="loader"></div>
            </div>
        </>
    )
};

export default Loader;