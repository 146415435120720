import React, {useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import $ from 'jquery';
import swal from "sweetalert2";

function ResetPassword () {
  


    const [showPassword, setShowPassword] = useState(false);      
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
    const [confPassword, setConfPassword] = useState(false);   
    const togglePasswordconfirmVisibility = () => {
        setConfPassword(!confPassword);
      };
      const defaultFormFields = {
        password:'',
confPassword:'',
      }
      const [formFields, setFormFields] = useState(defaultFormFields);
      const [errorMessage, setErrorMessage] = useState('');
      const handleChange = (event) => {
          const { name, value } = event.target;    
          setFormFields({ ...formFields, [name]: value });
          if(name=="password")
          {
              if(!isPasswordStrong(value))
              {
                  setErrorMessage('Password should contain at least one number, one uppercase, one lowercase, one special character and minimam 6 letter.');                
              }
              else{
                  setErrorMessage('');
              } 
          }
  
          if(name =="Confirm_password"){
              if(formFields.password ==value)
                  {
                      $("#cnfrmpsmatch").html("");
                     // $("#cnfrmpsmatch").css("color","green");
                     // $("#cnfrmpsmatch").css("float","left");
                  }
                  else{
                      $("#cnfrmpsmatch").html("Password not Matched");
                      $("#cnfrmpsmatch").css("color","red");
                      $("#cnfrmpsmatch").css("float","left");
                      $("#cnfrmpsmatch").css("position","relative");
                      $("#cnfrmpsmatch").css("top","-10px");
                      $("#cnfrmpsmatch").css("font-size","9px");
                  }
          }
  
  
        };
      const isPasswordStrong = (password) => { 
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/;
        return passwordRegex.test(password);
      };

///////////////////////////////////////////////  handleresetpassword  ////////////////////////////////
const handleresetpassword =() =>{
if($("#password").val()==""){
  swal.fire({
    title: '<span style="color:#f27474">Opps!</span>',
    html: "Please enter password <br /><br /><a href='javascript:swal.close();' class='btn btn-primary btn-sm'>OK</a>",
    type: 'error',
    icon: "error",
    showCloseButton: true,
    showCancelButton: false,
    showConfirmButton: false
});
return;
}

if($("#password").val() != $("#Confirm_password").val()){
  swal.fire({
    title: '<span style="color:#f27474">Opps!</span>',
    html: "Password not matched! Try Again <br /><br /><a href='javascript:swal.close();' class='btn btn-primary btn-sm'>OK</a>",
    type: 'error',
    icon: "error",
    showCloseButton: true,
    showCancelButton: false,
    showConfirmButton: false
});
return;
}

    const axios = require('axios');

    let datas = JSON.stringify({
      "passworddata": {           
       "password":$("#password").val(),
       "Confirm_password":$("#Confirm_password").val(), 
       "user":$("#hdnregistrationid").val(), 
       "ecode":$("#hdnemail").val(), 
      }
    });
    //console.log(datas);
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      headers: { 
        'Content-Type': 'application/json'
      },
      body : datas
    };
    
    fetch('https://zwt.zwato.com/ResetPassword.aspx/ReactResetPassword', config)
    .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json(); // Extract the response body as JSON
      })
      .then(data => {         
        if (data.d === 'success') {          
            swal.fire({
                title: '<span style="color:#5ab328">Reset Password!</span>',
                html: "Your password has been reset successfully!<br /><br /><a href='/signin' class='btn btn-primary btn-sm'>Click here to login</a> ",
                type: 'success',
                icon: "success",
                showCloseButton: true,
                showCancelButton: false,
                showConfirmButton: false
            });
         return;
        }
        else {
           swal.fire({
               title: '<span style="color:#f27474">Opps!</span>',
               html: "Try again. <br /><br /><a href='javascript:swal.close();' class='btn btn-primary btn-sm'>OK</a>",
               type: 'error',
               icon: "error",
               showCloseButton: true,
               showCancelButton: false,
               showConfirmButton: false
           });
           return;
        }
      })
    .catch((error) => {
      console.log(error);
    });


}

    useEffect(() => {

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let email = params.get('ecode'); 
        let regno = params.get('user');
       // alert(params.get('user').replace(" ","+")); 
$("#hdnemail").val(email.replace(" ","+"));
$("#hdnregistrationid").val(regno.replace(" ","+"));
              
      }, []);


    let publicUrl = process.env.PUBLIC_URL + '/'
    return (
<>
<input type="hidden" id="hdnemail" />
<input type="hidden" id="hdnregistrationid" />
<section className="cont-area h-100">
    <div className="login-8 ">
<div className="container h-100">
	<div className="row h-100 justify-content-center align-items-center">		
		<div className="col-md-5">
			<div className="card login-inner-form mt-4">
                <div className="card-header text-center pb-0 mb-0 mt-4">                    
                    <h4>Reset Your Password</h4>	
                </div>
                <hr/>
                <div className="card-body">			
                    <Form.Group className="form-group form-box">
                        <Form.Control  type={showPassword ? "text" : "password"} name="password" id="password" onChange={handleChange} placeholder="************"/>
                        <i onClick={togglePasswordVisibility}>
                          {showPassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}          </i>
                    </Form.Group>
                    {errorMessage && <p style={{ color: 'red', fontSize:'9px', textAlign:'left', position:'relative', top:'-10px', clear:'both' }}>{errorMessage}</p>}
                    <Form.Group className="form-group form-box">
                        <Form.Control  type={confPassword ? "text" : "password"} name="Confirm_password" id="Confirm_password" onChange={handleChange} placeholder="************"/>
                        <i onClick={togglePasswordconfirmVisibility}>
                          {confPassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}          </i>
                    </Form.Group>
                    <span id="cnfrmpsmatch"></span>
                    <div className="form-group mb-0">
                        <Button className="btn btn-info-gradien w-100" onClick={handleresetpassword} type="submit"><i class="fa-solid fa-edit"></i> Reset Password</Button>                    
                    </div>

                    <div className="small text-center"> <br /> Already Have an Password? <a href="/signin">Sign In</a></div>


                </div>

               
				
			</div>
			
		</div>
	</div>
</div>
</div>
	</section>
</>
    );
}
export  default ResetPassword;
